import { useBuildHandoffUrl } from '@cma/common'
import { getAccessTokens, useLogout } from '@cma/features/auth'
import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarNavigationBar,
  NavbarUserMenu
} from '@helix/navbar'
import { IPlatformService } from '@lwolf-common/platform-service'
import PlatformService from '@lwolf-common/platform-service/build/PlatformService'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { UserRole, useCurrentUser } from './useCurrentUser'

export function Navigation() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const { data: appSwitcher } = useAppSwitcher()
  const buildHandoffUrl = useBuildHandoffUrl()
  const logout = useLogout()
  const accessTokens = getAccessTokens()

  return (
    <Navbar logoUrl={buildHandoffUrl('/')} dataLwtId="default">
      <ApplicationSwitcher
        applicationName="Cloud CMA"
        appSections={appSwitcher?.appSections || []}
        launchpadUrl={process.env.VITE_APP_LW_PLATFORM_URL}
      />
      <NavbarNavigationBar
        navItems={[
          { label: 'CMA', url: '/cmas' },
          { label: 'Buyer Tour', url: '/tours' },
          { label: 'Property', url: '/properties' },
          { label: 'Presentation', url: '/presentations' },
          currentUser?.features?.hasFlyers
            ? { label: 'Flyer', url: '/flyers' }
            : undefined,
          currentUser?.features?.hasDocuments
            ? { label: 'Documents', url: '/documents' }
            : undefined,
          currentUser?.features?.hasHomebeat ||
          currentUser?.features?.offerHomebeat
            ? { label: 'Homebeat', url: '/homebeats' }
            : undefined
        ].filter((item): item is { label: string; url: string } => !!item)}
        linkWrapper={({ item }) => {
          if (
            item.url ===
            `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser?.account?.id}`
          ) {
            return <a href={item.url}>{item.label}</a>
          }

          const isExternal = ['Learning Resources', 'Support Center'].includes(
            `${item.label}`
          )

          if (isExternal) {
            return (
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.label}
              </a>
            )
          }

          return <Link to={item.url || ''}>{item.label}</Link>
        }}
      />
      <NavbarAdditionalItems>
        <NavbarUserMenu
          user={{
            name: currentUser?.name,
            email: currentUser?.email,
            avatarURL: currentUser?.avatar || undefined
          }}
          menuItems={[
            { label: 'Account Settings', url: '/settings' },
            (currentUser?.role || 0) >= UserRole.ADMIN_PLUS
              ? { label: 'Brokers', url: '/admin/brokers' }
              : currentUser?.features?.hasBrokerAccess
              ? { label: 'Dashboard', url: '/dashboard' }
              : // Old dashboard for backward compatibility
              currentUser?.isAdmin && currentUser.account?.isSmallBroker
              ? {
                  label: 'Dashboard',
                  url: `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser.account?.id}`
                }
              : {},
            { divider: true },
            { label: 'Custom Pages', url: '/settings/custom-pages' },
            currentUser?.features?.hasApiButton
              ? { label: 'Lead Generation', url: '/settings/lead-generation' }
              : {},
            { divider: true },
            {
              label: 'Learning Resources',
              url: 'https://learn.cloudagentsuite.com/videos-cma'
            },
            {
              label: 'Support Center',
              url: 'https://lonewolf.my.site.com/s/cloud-cma-resources'
            },
            { divider: true },
            {
              label: accessTokens?.impersonateToken
                ? 'Back to your account'
                : 'Sign Out',
              onClick: () => logout({ shouldUseCmaToLogout: true })
            }
          ].filter((item) => !!Object.keys(item).length)}
          linkWrapper={({ item }) => {
            if (
              item.url ===
              `${process.env.VITE_APP_CMA_URL}/admin/account/${currentUser?.account?.id}`
            ) {
              return <a href={item.url}>{item.label}</a>
            }

            const isExternal = [
              'Learning Resources',
              'Support Center'
            ].includes(`${item.label}`)

            if (isExternal) {
              return (
                <a href={item.url} target="_blank" rel="noreferrer">
                  {item.label}
                </a>
              )
            }

            return <Link to={item.url || ''}>{item.label}</Link>
          }}
        />
      </NavbarAdditionalItems>
    </Navbar>
  )
}

export function useAppSwitcher() {
  const { data: { currentUser } = {} } = useCurrentUser()
  const lwaJwt = currentUser?.lwaJwt || ''

  return useQuery(
    ['appSwitcher', { lwaJwt }],
    async () => {
      const platformService: IPlatformService =
        await PlatformService.initialize({
          lwDomain: process.env.VITE_APP_LW_URL,
          token: lwaJwt,
          appId: 'cc'
        })

      const appSections = await platformService.getAppSections()

      return { appSections }
    },
    {
      suspense: false,
      retry: false,
      enabled: !!lwaJwt
    }
  )
}
