import { ElementType, ForwardedRef, forwardRef } from 'react'
import { Spinner } from './Spinner'
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef
} from './polymorphic'
import { classNames } from './utils'

const defaultElement = 'button'

interface OwnButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  outline?: boolean
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
}

export type ButtonProps<T extends ElementType = typeof defaultElement> =
  PolymorphicPropsWithoutRef<OwnButtonProps, T>

export const Button: PolymorphicForwardRefExoticComponent<
  OwnButtonProps,
  typeof defaultElement
> = forwardRef(function Button<T extends ElementType = typeof defaultElement>(
  {
    variant = 'primary',
    size = 'md',
    leftIcon,
    rightIcon,
    outline,
    fullWidth,
    loading,
    disabled,
    children,
    as,
    ...props
  }: ButtonProps<T>,
  ref: ForwardedRef<Element>
) {
  // TODO: Figure out why we can't omit the className
  // TODO: Remove this once the above is fixed
  if (props.className) {
    throw new Error('You cannot add classNames to the button')
  }

  const isDisabled = disabled || loading
  const Element: ElementType = as || defaultElement

  return (
    <Element
      {...props}
      ref={ref}
      disabled={isDisabled}
      className={classNames(
        'helix-btn inline-flex items-center justify-center hover:no-underline',
        {
          // Variants
          // When creating a new variant, base it off of the primary variant.

          // Primary
          // Solid
          'border-transparent bg-brand-primary text-white':
            variant === 'primary' && !outline,
          'hover:border-transparent hover:bg-brand-primary focus:bg-brand-primary active:border-transparent active:bg-green-700':
            variant === 'primary' && !outline && !isDisabled,
          // Primary
          // Outline
          'helix-text-brand border-brand-primary':
            variant === 'primary' && outline,
          'hover:helix-text-brand focus:helix-text-brand hover:border-brand-primary focus:border-brand-primary active:border-green-600 active:text-green-600':
            variant === 'primary' && outline && !isDisabled,

          // Primary
          'helix-btn--primary': variant === 'primary',
          // Secondary
          'helix-btn--secondary': variant === 'secondary',
          // ghost
          'helix-btn--ghost': variant === 'ghost',
          // Danger
          'helix-btn--danger': variant === 'danger',
          // Tertiary
          // Solid
          'helix-text-brand border-transparent bg-gray-100':
            variant === 'tertiary' && !outline,
          'hover:helix-text-brand focus:helix-text-brand active:helix-text-brand hover:border-transparent active:border-transparent':
            variant === 'tertiary' && !outline && !isDisabled,

          // Tertiary
          // Outline
          'helix-text-brand border-gray-200': variant === 'tertiary' && outline,
          'hover:helix-text-brand focus:helix-text-brand active:helix-text-brand':
            variant === 'tertiary' && outline && !isDisabled,

          // Size
          'rounded px-2.5 py-1.5 text-xs': size === 'xs',
          'helix-btn--small': size === 'sm',
          'helix-btn--medium': size === 'md',
          'helix-btn--large': size === 'lg',
          'rounded-md px-6 py-3 text-base': size === 'xl',
          'rounded-md px-6 py-3 text-xl': size === '2xl',
          'rounded-md px-6 py-4 text-2xl': size === '3xl',

          // Width
          'w-full': fullWidth,

          // Disabled
          'cursor-pointer': !isDisabled,
          'cursor-not-allowed opacity-50': isDisabled
        }
      )}>
      {!leftIcon && !rightIcon && loading && (
        <span
          className={classNames('-ml-1 mr-2 flex-shrink-0', {
            'h-4 w-4': ['xs', 'sm'].includes(size),
            'h-5 w-5': ['md', 'lg', 'xl'].includes(size)
          })}>
          <Spinner />
        </span>
      )}
      {leftIcon && (
        <span
          className={classNames('-ml-1 mr-2 flex-shrink-0', {
            'h-4 w-4': ['xs', 'sm'].includes(size),
            'h-5 w-5': ['md', 'lg', 'xl'].includes(size)
          })}>
          {loading ? <Spinner /> : leftIcon}
        </span>
      )}
      {children}
      {rightIcon && (
        <span
          className={classNames('-mr-1 ml-2 flex-shrink-0', {
            'h-4 w-4': ['xs', 'sm'].includes(size),
            'h-5 w-5': ['md', 'lg', 'xl'].includes(size)
          })}>
          {loading ? <Spinner /> : rightIcon}
        </span>
      )}
    </Element>
  )
})
